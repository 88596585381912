import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { charityTheme } from 'common/theme/charity';
import { ResetCSS } from 'common/assets/css/style';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import Navbar from 'containers/Charity/Navbar';
import DrawerSection from 'containers/Charity/DrawerSection';
import Footer from 'containers/Charity/Footer';

import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from 'containers/Charity/charity.style';
import Seo from 'components/seo';

const Index = () => {
  return (
    <ThemeProvider theme={charityTheme}>
      <Fragment>
        <Seo
          title="Wildlife"
          description="Information about the different species of Wildlife residing in the meadows."
        />
        <ResetCSS />
        <GlobalStyle />
        {/* Start charity wrapper section */}
        <CharityWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <Navbar />
          </Sticky>
          <DrawerProvider>
            <DrawerSection />
          </DrawerProvider>
          <ContentWrapper style={{ padding: '2em' }}>
            <h1>Cookie Policy</h1>

            <p>Last Updated: 28th October 2021</p>

            <h2>1. INTRODUCTION</h2>

            <p>The Lammas Lands Preservation Group (“we” or “us” or “our”) may use cookies, web beacons, tracking pixels, and other tracking technologies when you visit our website lammaslands.com, including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the “Site”) to help customize the Site and improve your experience.</p>

            <p>We reserve the right to make changes to this Cookie Policy at any time and for any reason. We will alert you about any changes by updating the “Last Updated” date of this Cookie Policy. Any changes or modifications will be effective immediately upon posting the updated Cookie Policy on the Site, and you waive the right to receive specific notice of each such change or modification.</p>

            <p>You are encouraged to periodically review this Cookie Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Cookie Policy by your continued use of the Site after the date such revised Cookie Policy is posted.</p>

            <h2>2. USE OF COOKIES</h2>

            <p>A “cookie” is a string of information which assigns you a unique identifier that we store on your computer. Your browser then provides that unique identifier to use each time you submit a query to the Site. We use cookies on the Site to, among other things, keep track of services you have used, record registration information, record your user preferences, keep you logged into the Site, facilitate purchase procedures, and track the pages you visit. Cookies help us understand how the Site is being used and improve your user experience.</p>

            <h2>3. TYPES OF COOKIES</h2>

            <p>The following types of cookies may be used when you visit the Site:</p>

            <h3>Analytics Cookies</h3>

            <p>Analytics cookies monitor how users reached the Site, and how they interact with and move around once on the Site. These cookies let us know what features on the Site are working the best and what features on the Site can be improved.</p>

            <h2>4. CONTROL OF COOKIES</h2>

            <p>Most browsers are set to accept cookies by default. However, you can remove or reject cookies in your browser’s settings. Please be aware that such action could affect the availability and functionality of the Site.</p>

            <p>For more information on how to control cookies, check your browser or device’s settings for how you can control or reject cookies, or visit the following links:</p>

            <p><a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">Apple Safari</a></p>

            <p><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=en">Google Chrome</a></p>

            <p><a href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd" target="_blank" rel="noopener noreferrer">Microsoft Edge</a></p>

            <p><a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">Microsoft Internet Explorer</a></p>

            <p><a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&amp;redirectlocale=en-US" target="_blank" rel="noopener noreferrer">Mozilla Firefox</a></p>

            <p><a href="https://help.opera.com/en/latest/">Opera</a></p>

            <p><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&amp;hl=en&amp;oco=1">Android (Chrome)</a></p>

            <p><a href="https://docs.blackberry.com/content/dam/docs-blackberry-com/release-pdfs/en/device-user-guides/BlackBerry-Classic-Smartphone-10.3.3-User-Guide-en.pdf">Blackberry</a></p>

            <p><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DiOS&amp;hl=en&amp;oco=1">Iphone or Ipad (Chrome)</a></p>

            <p><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&amp;hl=en&amp;oco=1">Iphone or Ipad (Safari)</a></p>

            <p>In addition, you may opt-out of some third-party cookies through the <a href="http://optout.networkadvertising.org/?c=1">Network Advertising Initiative’s Opt-Out Tool</a>.</p>

            <h2>PRIVACY POLICY</h2>

            <p>For more information about how we use information collected by cookies and other tracking technologies, please refer to our <a href="/privacy">Privacy Policy</a> posted on the Site. This Cookie Policy is part of and is incorporated into our Privacy Policy. By using the Site, you agree to be bound by this Cookie Policy and our Privacy Policy.</p>

            <h2>CONTACT US</h2>

            If you have questions or comments about this Cookie Policy, please contact us at privacy@lammaslands.com

          </ContentWrapper>
        </CharityWrapper>
        {/* End of charity wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
};
export default Index;
